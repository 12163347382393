<template>
    <div class="hmw_box" :style="{backgroundColor:data.color}">
        <p>{{data.caption}}</p>
    </div>
</template>

<script>

    export default {
        data(){
            return{


            }
        },
        props:{
             data:{
                type:Object
            }
        }
    }
</script>

<style >
.hmw_box{
    width: 240px;
    height: 210px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 4px;
filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.119));
margin: 8px;

}
.hmw_box p{
    font-weight: 400;
    color:#5A5A5A;
    line-height: 23px;
}
</style>