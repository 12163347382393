!<template>
    <div class="container">
        <h2>Problem</h2>
        
        <div class="team newdiv">
            <h3>Problem</h3>
            <div class="textnoimage">
                <p >
                    Two primary issues were identified:
                        Many of the company's customers expressed dissatisfaction with their product experience.
                        a new customer segment was introduced, comprising individuals with limited data knowledge.
                </p>
            </div>
        </div>
        <div class="duration newdiv">
            <h3>Outcome</h3>
            <div class="row">
                <div class="text ">
                    <p >
                        To work persistently, we've set a clear outcome, which helps us prioritize both user needs and business value. so that we have to declare user goal and business value both, here is the outcome:
                    </p>
                    <p>
                        <b>Simplify data visualization for users with limited data expertise while enhancing satisfaction among our current user base. 
</b>
                    </p>
                    
                </div>
                <div class="img">
                    <img :src="goal" class="timeline" alt="">
                </div>
            </div>
        </div>

        <div class="decisions newdiv">
            <h3>Limits</h3>
            <div class="textnoimage">
                <p >
                    Due to time constraints and a shortage of people available, our main focus is on improving the flow of data visualization, instead of getting into the detailed aspects of charts and extra features.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import goal from "../../assets/images/datavisualization/usergoal.png"
    export default {
        data(){
            return{
                goal
            }
        
        
    }
}
</script>

<style scoped>
.container{
    padding-bottom: 60px;
}
.text{
width: 40%;
}
.img{
    width: 60%;
    padding: 40px;
    box-sizing: border-box;

}

.timeline{
    width: 100%;
}
.container{

}
.bullet{
    font-size: 18px;

}
</style>