<template>
    <div class="second_cond">
        
        <h2 class=" bigh2 change_font galh2">proccess</h2>

        <h2 class="medh2  mb-5 galh2" > The viewers select the artworks that they are interested in.</h2>

        <div class="row">
            <div class="firstsectiondescription">
            <!--    <img :src="check" alt="" class="arrow-icon2"> -->
                <h3 class="threeparts galh3">
                    <span class="golden_color">1- There </span>are some sample images to find users test
                </h3>
                <div class="desciption">
                    <p class="galp">A collection of artworks with diverse range of styles is prepared for the user to select </p>
                </div>
                <div class="twotwosection margin-top2">
                    <h3 class="threeparts galh3">
                        <span class="golden_color">2- The</span> users create their collection by selecting artworks
                    </h3>
                    <div class="desciption">
                        <p class="galp">Each image in the collection is scored across multiple categories, and selecting an image will increase the user's score in those categories.</p>
                    </div>
                </div>
            </div>


            <div class="firstsecimagediv">
                <img :src="selectedimages" class="firstsecimage mulimage">
            </div>
            <div class="firstsectiondescription margin-top">
                <h3 class="threeparts galh3">
                    <span class="golden_color">some</span> of these categories are:
                </h3>
                <div class="desciption">
                    <p class="galp">Realism <br>
                        Impressionism <br>
                        Expressionism <br>
                        Abstract Expressionism <br>
                        Surrealism <br>
                        Pop Art <br>
                        Baroque <br>
                        ...
                    </p>
                </div>
            </div>
         
        </div>
        <div class="nextpath">
                
            <div class="row">
                <div class="onefifth desciption pa">
                    <h2 class="galh2">
                        <h2 class="medh2 galh2 mb-5" >  Artmatch measures the similarity of users' selections within each room of art gallery</h2>
                    </h2>
                    <p class="galp">
                        Artmatch evaluates the user's selection score for different art styles and adapts to their preferences by prioritizing rooms with higher scores in the specific style of interest. This ensures that the user is presented with rooms that align with their preferences. The rooms are then ordered based on the user's style score, with the most relevant rooms appearing first. This approach enables Artmatch to effectively cater to the user's unique tastes and preferences, providing a personalized and enjoyable experience.
                    </p>
                </div>
                <div class="fourfifth ">
                    <img :src="oneimg" alt="" class="firstsecimage twoimage">
                </div>
            </div>

        </div>
                
      
    </div>
</template>

<script>
import selectedimages from "../../assets/images/selectedimages.png"
import check from "../../assets/icons/check-double-fill.svg"
import oneimg from "../../assets/images/oneimg.png"

    export default {
        data(){
            return{
                selectedimages,
                check   ,
                oneimg 
            }
        }
    }
</script>

<style scoped>
h3{
    margin-block-start: .3em;
    margin-block-end: .3em;
}
h2{
    margin-block-start: 0em;
    margin-block-end: 0em;
}
.firstsectiondescription{
    width: 20%;
    margin-top: 6rem;
    padding-inline: 4px;
    box-sizing: border-box;
}
.firstsecimagediv{
    width: 59.9%;

    position:relative;
}
.medh2{
    font-size: 18pt;
    margin-top:10px;
    line-height: 30px;
}
.firstsecimage{

    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
.mulimage{
    width: 70%;
}
.twoimage{
    width: 95%;
}
.second_cond{
    margin-top: 150px;
}
.bigh2{
    font-size: 36pt;
}
.golden_color{
    color: #B19C87;
}
.space{
    width: 20%;
}
.lineh{
    line-height: 30px;
}
.desc{
    width: 60%;
    color: #717171;
}
.marg{
    margin-bottom: 100px;
}
.desciption p{
    color: #717171;
    text-align: justify;
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-size: 11pt;
}
.numberformat{
    font-size: 42pt;
    line-height: 80px;
    color: #B19C87;
}
.numlineh{
    line-height: 10px;
}
.threeparts{
    line-height: 28px;
}
.arrow-icon2{
    color:#B19C87;

}
.change_font{

 margin: 0;
}
.nextpath{
    margin-top: 128px;
}
.margin-top{
    margin-top: 250px;
}
.margin-top2{
    margin-top: 200px;
}
.onefifth{
    width: 40%;
}
.pa{
    padding:5px;
    box-sizing: border-box;
}
.fourfifth{
    width: 60%;
}
</style>