<template>
    <div class="about_container">
      <div class="row">
        <div class="about_text">
          <p class="about_textp">books</p>
            <ul>
                <li>The Design of Everyday Things</li>
                <li>Continuous Discovery Habits</li>
                <li>100 Things Every Designer Needs to Know About People</li>
                <li>Hooked: How to Build Habit-Forming Products</li>
                <li>sprint</li>
                <li>lean UX</li>
            </ul>
        </div>
        <div class="about_text">
          <p class="about_textp">certificates</p>
          <ul>
                <li>UX research - methods and best practice</li>
                <li>Journey Mapping</li>
                <li>Google UX Course - Part 1,2,3</li>
                <li>Design system</li>
            </ul>
        </div>
       
  
      </div>
    </div>
  </template>
  
  <script>

  export default {
    name: 'About',
    data(){
      return{
      }
    }
    
  }
  </script>
  
  <style scoped>
  .about_container{
    padding-inline: 4%;
    padding-top: 2%;
    min-height: 600px;
    height: 100%;
    background-color: #00020D;
  }
  .about_text{
    width: 50%;
    color: rgb(243, 243, 243);
      
    box-sizing: border-box;
  padding-right: 30px;
  
  }
  
  .new{
    margin-top: 4px;
  }
  .about_img{
    width: 100%;
  }
  .about_textp{
   font-weight: 800;
    color: rgb(243, 243, 243);

    font-size: 20pt;

    
  }li{
    font-size: 15pt;
  }
  </style>
  