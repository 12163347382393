<template>
    <div>
        <div class="lightcontainer">
            <div class="lightselector">
                
            </div>
            <div class="light"></div>
            <div class="header">
                <img :src="header" alt="" class="headerproperty">
            </div>
    
            <div class="board">
                <div class="insider">
                    <h2 class="textofdiv">Data visualization <br/>
                        <span class="lightfont"> that does everything</span></h2>
                </div>
            </div>

        </div>
      
    </div>
</template>

<script>

import header from "../../assets/images/datavisualization/header.png"
    export default {
        data(){
            return{
                header
            }
        }
    }
</script>

<style scoped>

h2{
    text-align: center;
    background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.16) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


}
.textofdiv{
    animation-name: h2;
  animation-duration: 2s;

  animation-delay: 1s;
}
.board{
width: 600px;
height: 200px;
flex-shrink: 0;
position: relative;
animation-name: example;
  animation-duration: 2s;

  animation-delay: 1s;
    left: 50%;
    top: 25%;
    transform: translate(-50%,-50%);

    border-radius: 16px;
    border: 1px solid transparent;
    background: linear-gradient(00020D, 00020D) padding-box,
              linear-gradient(to bottom, rgb(119, 119, 119), 00020D) border-box;

    box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.50), 0px 12px 50px -18px rgba(255, 255, 255, 0.20) inset;
    backdrop-filter: blur(6px);

}
.insider{

}
.header{
    margin-top: 40px;
    position: absolute;
    top: 35%;

}
.lightfont{
    font-weight: 100;
    font-size: 42px;
    padding-top: 20px;
}
.firstimage{
    width: 100%;
    height: 100vh;
    position: relative;
}
.lightcontainer{
width: 100%;
position: relative;
height: 100vh;

}
.headerproperty{
    width: 100%;
}
.light{
width: 500px;
height: 100px;
position: absolute;
left: 50%;
    top: 25%;
    transform: translate(-50%,-50%);

box-shadow: 0px 42px 100px -4px rgba(151,164,237,1);
-webkit-box-shadow: 0px 42px 100px -4px rgba(151,164,237,1);
-moz-box-shadow: 0px 42px 100px -4px rgba(151,164,237,1);
}
.lightselector{
    left: 50%;
    top: 20%;
    transform: translate(-50%,-50%);
    position: relative;
background-color: #00020D;
    width: 700px;
    height: 200px;
    z-index: 2;
    border-radius: 30px;
}
@keyframes example {
  0% {top:70%;
        box-shadow: 0px -1px 0px 0px rgba(130, 130, 130, 0.5), 0px 12px 50px -18px rgba(61, 61, 61, 0.2) inset;
    }
  100% {top:25%;
        box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.50), 0px 12px 50px -18px rgba(255, 255, 255, 0.20) inset;
    }
}
@keyframes h2 {
  0% {opacity: 0}
  100% {opacity: 1  }
}
</style>