<template>
    <div>
        <p class="section_title ">the problem</p>
        <p class="section_caption">
            In the wake of the COVID-19 pandemic, many schools worldwide transitioned to online education via platforms like mobile or laptop devices. While virtual learning enabled students to acquire knowledge, the quality of their learning experience may have been suboptimal. Furthermore, the pandemic had a significant impact on students' emotional and behavioral well-being, leading to feelings of isolation. Home learning presented several challenges for students, including technological difficulties, high internet costs, and limited opportunities for interaction and socialization with peers. 
        </p>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style >

</style>