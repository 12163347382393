<template>
    <div class="ThreeDContainer">
        <div class="contain">
            <img class="envirement" :src="envirement" alt="chool envirement">
        </div>
    </div>
</template>

<script>
import envirement from "../../assets/images/envirement.png"
    export default {
        data(){
            return{
                envirement:envirement
            }
        }
        
    }
</script>

<style scoped>
.ThreeDContainer{
    height: 80vh;
    margin-top: 90px;
}
.contain{
    width: 100%;
    height: 80vh;
    background-color: #E2E6F7;
    position: absolute;
    left: 0;
}
.envirement{
    width: 80%;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

}
</style>