<template>

        <div class="pro_box">
            <p class="num">{{pro.num}}</p>
            <p class="sec_tit">{{pro.title}}</p>
            <p class="sec_cap">{{pro.caption}}</p>
        </div>

</template>

<script>
    export default {
        props:{
           pro:{
                type:Object
                
            }
        }
    }
</script>

<style >
.pro_box{
    padding: 10px;
    padding-inline: 15px;
    background: #E2E6F7;
    min-height: 180px;
}
.num{
    position: absolute;
    color: rgba(255, 255, 255, 0.64);
    font-size: 38pt;
    font-weight: 800;
    line-height: 0px;
    margin-top: 20px;
    z-index: 1;
}
.sec_tit{
margin-top: 10px ;
font-size: 13pt;
text-align: center;
font-weight: 500;
    color: rgb(29, 29, 29);
    z-index: 2;
}
.sec_cap{
    margin-top: 10px ;
    text-align: center;
    font-size: 12pt;
    font-weight: 300;
    line-height: 20px;
    color: rgb(90, 90, 90);

}

</style>