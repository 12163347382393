<template>
    <div>
        <p class="case">Case studies</p>
        <Datavisualization :project="projects[5]" />
        <CaseStudy :project="projects[0]" />
        <galleryroom :project="projects[1]" />
        <!-- <Personal :project="projects[3]" />
        <CaseStudRight :project="projects[2]" />-->
        <!--<DailyUi :project="projects[3]"></DailyUi>-->
    </div>
</template>

<script>
import env from "../assets/images/env3.png"
import Datavisualizationthumbnail from "../assets/images/Datavisualizationthumbnail.png"
import gallery from "../assets/images/gallery.png"
import social from "../assets/images/social.png"
import personal from "../assets/images/personal.png"
import CaseStudy from "../components/CaseStudy"
import CaseStudRight from "../components/CaseStudRight"
import galleryroom from "../components/galleryroom"
import Personal from "../components/personal"
import Datavisualization from "../components/Datavisualization"

    export default {
        data() {
            return{
                projects:[
                    {title:"Virtual School for Students",caption:"this is a virtual class for students to help themstudy in online clases",image_src:env,link:'/project/virtualschool',image_align:'right'},
                    {title:"Art Exhibition with a Recommender System",caption:"this is an art exhibition in which artists and Gallery owners  can display their artworks. a recommender system is also provided to suggest artworks to every user base on their taste",image_src:gallery,link:'/project/gallery',image_align:'left'},
                    {title:"Social Media with high Accebility",caption:"this is a virtual class for students to help themstudy in online clases",image_src:social,link:'/aaa',image_align:'right'},
                    {title:"Personal Website",caption:"this website was my first website",image_src:personal,link:'/aaa',image_align:'right'},
                    {title:"+30 daily UI challenges",caption:"in drow +30 UI shot by 30 days. each day 1 shot.",image_src:personal,link:'/aaa',image_align:'right'},
                    {title:"Modern Data Visualization",caption:"Introducing a new solution to create data visualizations with the help of AI",image_src:Datavisualizationthumbnail,link:'/project/Datavisualization',image_align:'left'},
                ]
            }
        },

        components:{
            CaseStudy,CaseStudRight,Personal,galleryroom,Datavisualization
        }
    }
</script>

<style >

.case{
    font-size: 50pt;
    font-weight: 600;
    font-family: 'DM Sans', sans-serif;
    
    color: #fff;
}
.case_cont{

}
.image_holder{
    height: 330px;
    position: relative;
}
.title{
    font-family: 'DM Sans', sans-serif !important;
    font-size: 32pt;
    margin: 0 !important;
    font-weight: 800;
    color: white;
    line-height: 46px;
}


.ptext{
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);

    margin: 0;
    font-weight: 300;
    line-height: 25px;

}
.project_link{
     font-weight: 600;
    font-size: 24px;
    text-decoration:underline;
        text-decoration-thickness: 8px;
}


@media screen and (max-width: 768px) {
    .tag{
        display: none
    }
    .title{
            font-size: 2.4rem;
    font-weight: 400;
    margin: 0 !important;
    line-height: 1.4;
    }
    .project_link{
     font-weight: 400;
    font-size: 20px;

    }
}
</style>