<template>
    <div>
        <div class="row  ">
            <h1 class="first_title2 galh1"> <span class="underline first_title9">Artmatch</span> : A personalized exhibition with recommander system</h1>
            <div class="link">
                <router-link  to="/sss" class="linktonext">
                    <div class="row mr-3">
                        <div class="icon_wraper">
                            <div class="icon">
                                <img src="../../assets/icons/arrow-right-line.svg" class="arrow-icon" alt="">
                            </div>
                        </div>
                        <p class="nextpro galp">next project</p>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="row ">
            <div class="firstsectiondescription ">
                <div class="tags row">
                    <p class="galp">AI</p>
                    <p class="galp">VR</p>
                </div>
                <div class="year row">
                    <div class="linecontainer">
                        <div class="linebefore"></div>
                    </div>
                    <p class="galp">2022</p>
                </div>
                <h3 class="galh3">
                    virtual exhabition that helps users to see what they like
                </h3>
                <div class="desciption">
                    <p class="galp">ArtMatch is a platform that provides exhibitors with a space to showcase their artworks. Using a clustering algorithm, artworks are categorized into rooms.</p>
                     <p class="galp">The platform also features a recommender system that analyzes viewers' interests and suggests appropriate rooms to explore first.  </p>
                </div>

            </div>
            <div class="EMP ">

             </div>
            <div class="firstsecimagediv">
                <img :src="firstimg" alt="" class="firstsecimage">
            </div>
         
        </div>
        <div class="summery ">
            <h2 class="change_font bigh2 galh2">summery</h2>
            <div class="row">
                <div class="p_section">
                    <p class="galp">
                        Visitors to art exhibitions may be overwhelmed by the variety of painting styles on display. To help them discover their preferred artworks, we have created a beautiful and functional exhibition space that uses a clustering algorithm to organize paintings by category in 6 rooms. By analyzing users' interests and behavior, we are able to offer personalized rooms that showcase the artworks that match their taste. Our  approach ensures that visitors have a seamless and enjoyable experience as they explore the world of art.
                    </p>
                </div>
                <div class="sec"></div>
                <div class="project_desciption">
                    <h4 class="nomarg galh4">Timeline</h4>
                    <p class="nomarg galp">3 weeks (May ‘22-August ‘22)</p>
                    <div class="pad mt-5"></div>
                    <h4 class="nomarg galh4">TOOLS</h4>
                    <p class="nomarg galp">blender - unity - javascript - PHP</p>
                    <div class="pad mt-5"></div>
                    <h4 class="nomarg galh4">people</h4>
                    <p class="nomarg galp">me and my professor</p>
                </div>
            </div>
        </div>
                
      
    </div>
</template>

<script>
import firstimg from "../../assets/images/first_gallery.png"
    export default {
        data(){
            return{
                firstimg
            }
        }
    }
</script>

<style scoped>

.firstsectiondescription{
    width: 20%;
    margin-top: 4rem;
    padding-inline: 4px;
    box-sizing: border-box;
}
.firstsecimagediv{
    width: 59.9%;

}

.firstsecimage{
    width: 100%;
    position: relative;
    right: 0;
    transition: .3s;

}
.change_font{
    font-size: 28pt;
 margin: 0;
}
.bigh2{
    font-size: 36pt;
}
.p_section{
width:60% ;
text-align: justify;
box-sizing: border-box;
padding: 10px;
}
.project_desciption{
width: 20%;
padding-inline: 10px;
box-sizing: border-box;
}
.sec{
    width: 20%;
}
.EMP{
    width: 20%;
}
.first_title2{
    font-size:32px;
    font-weight: 600;
    line-height: 3rem;
    margin-top: 0px;
    width: 80%;
    font-family: 'Poppins', sans-serif !important;
    color:   rgb(63, 63, 63);
}
.first_title9{
    font-size: 80px;
    font-weight: 900;
    word-spacing: 50;

    margin-top: 0px;
    font-family: 'Poppins', sans-serif !important;
    color: rgb(63, 63, 63);
}

.icon_wraper{
    width: 50px;
    height: 50px;
    border-radius: 100px;
    background-color: #B19C87;
    margin-inline: 1rem;
}
.arrow-icon{
    color: white;
    position: relative;
    transform: translate(-50%,50%);
    top: 50%;
    left: 50%;
}
.link{
    width: 20%;
    padding-inline:10px;
    box-sizing:border-box;
    position: relative;
}
.linktonext{
    position: absolute;
    right: 0;
}
.nextpro{
    position: relative;
    transform: translateY(-10%);
    top: 60%;
}
.year{
    position: relative;
}
.linecontainer{
    position: relative;
    width: 60px;
    margin-right: .5rem;
}
.linebefore{
    position: absolute;
    width: 60px;
    height: 0;
    border: .5px solid #B19C87;
    top: 50%;
    transform: translateY(-50%);

}
.nomarg{
    margin: 0;
}
.summery{
    margin-top: 128px;
}
.year p{
    width: 600;
    font-size: 20px;
    color:#B19C87;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.tags p{
    width: 500;
    font-size: 16px;
    color:#B19C87;
    margin-right: 1.5rem;
    margin-block-start: 0em;
    margin-block-end: 0em;

}
.firstsectiondescription h3{
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.desciption p{
    color: #717171;
    text-align: justify;
    font-family: "Proxima-nova";
}
@media screen and (max-width: 520px) {

    .section_title{
        font-size: 20px;
    }
    .section_caption{
        margin-left: 6%;
        font-size: 12pt;
    }
}
</style>