<template>
    <div class="hmw_container">
        <div class="cont">
            <div class="row1" ref="firspairs">
                <div v-for="hmw in hmws1" v-bind:key="hmw.id">
                    <HMW :data="hmw" />
                </div>
            </div>
            <div class="row1" ref="secondpairs">
                <div v-for="hmw in hmws2" v-bind:key="hmw.id">
                    <HMW :data="hmw" />
                </div>
            </div>
            <div class="row1" ref="thirdpairs">
                <div v-for="hmw in hmws3" v-bind:key="hmw.id">
                    <HMW :data="hmw" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HMW from "./HMW"
    export default {
        data(){
            return{
                hmws1:[
                    {id:1,caption:'"How might we encourage astudents to use microphone and camera and participate in class?"', color:'#FFEA9F'},
                    {id:2,caption:'"How might we make students more active in class?"', color:'#FFEA9F'},
                    {id:3,caption:'"How might we help students to concentrate during online class?"', color:'#FFEA9F'},
                    {id:4,caption:'"How might we do attendance?"', color:'#FFEA9F'},
                    {id:5,caption:'"How might we help students to have collaboration"', color:'#FFEA9F'},
                    ],
                hmws2:[
                    {id:1,caption:"How might we put goals for students?", color:'#FFDDDD'},
                    {id:2,caption:"How might we divide notifications?", color:'#FFDDDD'},
                    {id:3,caption:"How might weincrease autonomy?", color:'#FFDDDD'},
                    {id:4,caption:"How might we help teachers to evaluate assignments?", color:'#FFDDDD'},
                    {id:5,caption:"How might we make sequence beteen offline classes and messages", color:'#FFDDDD'},
                    ],
                hmws3:[
                    {id:1,caption:"How might we encourage them to study in group?", color:'#CBE9FF'},
                    {id:2,caption:"How might we help students to interduce with each other?", color:'#CBE9FF'},
                    {id:3,caption:"How might we encourage students to participate in new studing groups?", color:'#CBE9FF'},
                    {id:4,caption:"How might we encourage them to study for a particular time ?", color:'#CBE9FF'},
                    {id:5,caption:"How might we help students with low bandwidth?", color:'#CBE9FF'},
                    ]
            }
        },
        components:{
            HMW
        },
          created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
         methods: {
            handleScroll (event) {
            if(window.screen.width>920){
                if(Math.floor(window.scrollY)>2700 ){
                    this.$refs.firspairs.style.transform="translateX("+ (Math.floor( window.scrollY)-2750) *.2+"px)";
                    this.$refs.secondpairs.style.transform="translateX("+ -(Math.floor( window.scrollY)-2750) *.2+"px)";
                    this.$refs.thirdpairs.style.transform="translateX("+ (Math.floor( window.scrollY)-2750) *.2+"px)";

                }
            }
            else{
                
            }
        }

    }
}
</script>

<style >
.hmw_container{
min-height:700px;
overflow: hidden;
}
.cont{
    position: absolute;
    width: 100%;
    right: 0;
    left: 0;
    overflow: hidden;
}
.row1{
    display: flex;

}
</style>