<template>
    <div>
        <div class="project1_container">
            <h1 class="first_title">virtual school for students</h1>
            <TheMission/>
            <ThreeDSchool/>
            <TheProblem/>
            <ResearchInsights/>
            <Insights/>
            <theTest/>
            <FinalProduct/>
            <WhatILearned/>
        </div>
        

    </div>
</template>

<script>
import TheMission from "../../components/school/TheMission"
import theTest from "../../components/school/theTest"
import ThreeDSchool from "../../components/school/ThreeDSchool"
import TheProblem from "../../components/school/TheProblem"
import ResearchInsights from "../../components/school/ResearchInsights"
import Insights from "../../components/school/Insights"
import FinalProduct from "../../components/school/FinalProduct"
import WhatILearned from "../../components/school/whatILearned"
    export default {
        components:{
            TheMission,ThreeDSchool,TheProblem,ResearchInsights,Insights,theTest,FinalProduct,WhatILearned
        }
    }
</script>

<style >
.project1_container{
    margin-inline: 12%;
    background-color: #fff;
}
.first_title{
    font-size: 40px;
    font-weight: 600;
    text-decoration:underline;
    
    text-decoration-thickness:12px;
    text-decoration-color: #E2E6F7;
    color: #363636;
    margin-top: 92px;
}

.section_title{
    font-weight: 600;
    font-size: 28px;
    margin-top: 96px;
    color: #363636;
}
.section_caption{
    margin-left: 8%;
    font-weight: 300;
    color: #363636;
    line-height: 30px;
    font-size: 13pt;
}
.image_title{
    font-weight: 500;
    font-size: 18px;
    margin-top: 5px;
    color: #363636;
    text-align: center;
}

.section_title_2{
    margin-left: 8%;
    margin-top: 5%;
    font-weight: 550;
    font-size: 18px;
    color: #3e3e3e;
}
@media screen and (max-width: 520px) {
    .project_container{
        margin-inline: 0%;
    }
    .section_title{

        font-size: 20px;

    }
    .section_caption{
        margin-left: 6%;

        font-size: 12pt;
    }
}
</style>