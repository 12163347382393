<template>
    <div class="case_cont">
        <div class="row ">
            <div class="text">
                <div class="center">
                    <h2 class="title">{{project.title}}</h2>
                    <p class="ptext">{{project.caption}}</p>
                    <a href="https://alireza.netlify.app/" class="project_link project_link_color"> 
                        see website
                    </a>
                </div>
            </div>
              
            <div class="image">
                 <a href="https://alireza.netlify.app/"> 
                    <div class="image_holder ihSpecial">
                        <p class="ali">Alireza</p>
                        <img :src="project.image_src" class="env" alt="envirement">
                    </div>
                </a>
            </div>  
            <div class="tag">
               <p> 2020 - ui - coding</p>
            </div>
            
            
        </div>
    </div>
</template>

<script>

    export default {
         data: function () {
            return {
           
            
            }
        },
        props:{
            project:{
                type:Object
            }
        }
        
    }
</script>

<style scoped>

.image{
    width: 47.5%;
}
.ihSpecial{
      width: 85% ;
    background-color: #C2ABAB ;
}
.env{
    width:80%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.text{
    width: 45%;
}
.center{
    top: 50%;
    position: relative;
    transform: translateY(-50%);
    margin:5%;
}
.project_link_color{
    text-decoration-color: #C2ABAB;
}
.ali{
    color: #E9CCCC;
    font-size: 72pt;
    line-height: 10px;
    font-weight: 800;
    top: 80px;
    position: absolute;
    left: -40px;
}
.title{
    line-height: 55px;
}
.ptext{
    line-height: 25px;
}
@media screen and (max-width: 768px) {

   .text {
      width: 100%;
      order:2
   }
   .image {
      width: 100%;
      order: 1;
   }
   .center{
        top: 0%;
        position: relative;
        transform: translateY(0%);
        margin-right:0;
        margin-left:0;
    }

}

@media screen and (max-width: 920px) {
    .image_holder{
        min-height: 60vh; 
        margin-inline:0;  
        width: 100%;
    }
    .ptext{
        margin-bottom: 10px;
    }   
}
</style>