<template>
  <div class="about_container">
    <div class="row">
      <div class="about_text">
        <p class="new">
          Hello there! My name is Alireza Fatemi, and I'm a 23-year-old designer with a passion for creating innovative and imaginative solutions. For me, creativity is the lifeblood of any great design, and I'm constantly seeking new ways to push boundaries and explore new possibilities.
</p>
<p>
One of the things that sets me apart as a designer is my keen interest in the psychological impact of design on people. I'm fascinated by how the visual elements we create can influence emotions, behaviors, and even decision-making. I believe that great design isn't just about making things look good; it's about creating experiences that engage and delight the senses.
</p>
<p>
At the core of my design philosophy is a deep appreciation for the visual perspective. I believe that an eye-catching design can be a powerful tool for enhancing the human experience and making people feel better. Whether I'm working on a website, a logo, or a product, I always strive to create something that stands out and leaves a lasting impression.
</p>
<p>
If you're looking for a designer who is passionate, creative, and always looking for new challenges, then look no further. I'm excited to work with you and bring your vision to life!
        </p>

      </div>
      <div class="img_cont">
        <img :src="me" alt="" class="about_img">
      </div>

    </div>
  </div>
</template>

<script>
import me from '../assets/images/me.jpg'
export default {
  name: 'About',
  data(){
    return{
      me:me
    }
  }
  
}
</script>

<style scoped>
.about_container{
  padding-inline: 4%;
  padding-top: 2%;
}
.about_text{
  width: 65%;
    
  box-sizing: border-box;
padding-right: 30px;

}
.new{
  margin-top: 4px;
}
.img_cont{
  width: 35%;
  padding:1%;
  box-sizing: border-box;
}
.about_img{
  width: 100%;
}
.about_text p{

  font-family: "Proxima-nova";

    text-align: justify;
  font-size: 12pt;
  color: rgb(53, 53, 53);


  
}
</style>
