<template>
    <div class="colorbc">
        <lines></lines>
        <div class="project_container">
        <Firstsection/>
        <Secondsection/>
        <thirdSection/>
        <forthsection/>
        </div>
    </div>
</template>

<script>
import lines from "../../components/gallery/lines"
import Firstsection from "../../components/gallery/Firstsection"
import Secondsection from "../../components/gallery/Secondsection"
import forthsection from "../../components/gallery/forthsection"
import thirdSection from "../../components/gallery/thirdSection"
    export default {
        components:{
            
        },
        components:{
            lines,Firstsection,Secondsection,forthsection,thirdSection
        }
    }
</script>

<style >
.colorbc{
    background-color: #fff;

    padding-top: 100px!important;
}
.galp{
    color: #595959;
    font-family:'Proxima-Nova-2'
}
.galh1{
    color: rgb(50, 50, 50);
}
.galh2{
    color: rgb(50, 50, 50);
    line-height: 55px;
}
.galh3{
    color: #3b3b3b;
    line-height: 30px;
    font-weight: 600;
}
.galh4{
    
}
.project_container {
    margin-inline: 40px;
    position:relative;
}


@media screen and (max-width: 520px) {
    .project_container{
        margin-inline: 0%;
    }

}
</style>