!<template>
    <div class="container">
        <h2>result </h2>
        
        <div class="team newdiv">
            <h3>Sketch </h3>
            <div class="textnoimage">
                <p>
                    We generated over 60 sketches to refine the structural elements of our project. After sketching our ideas, we seamlessly transitioned to the creation of high-fidelity prototypes, bypassing the wireframing phase.
                </p>
  
            </div>
            <img :src="sketch" class="compite" alt="">
        </div>

        <div class="team newdiv">
            <h3>Prototype</h3>
            <h4>Insert data</h4>
            <img :src="insert1" class="compite marg" alt="">
            <img :src="insert2" class="compite marg" alt="">
            <img :src="insert3" class="compite marg" alt="">
        </div>

        <div class="team newdiv">
            <h3>create chart</h3>
            <div class="textnoimage">
                <p>After our interviews and exploring different opportunities, we realized something essential: "Users care more about their goals than charts." With this insight in mind, we designed a system to help users overcome the challenges of choosing charts and setting parameters. Rather than asking users to handle these tasks, we invite them to input the specific measurements or data they want to track, and we'll provide the corresponding charts and tables based on their preferences. lets explain the method.
                </p>
                <h4>magical Box</h4>
                <p>In the magical Box, users can input parameters they wish to track, encompassing three distinct categories:
                    <ul>
                        <li><b> Measure:</b>  These are quantifiable metrics like "Total Cost" or "Sales Revenue."</li>
                        <li>Aggregation: Measures can be aggregated, allowing users to summarize and visualize data in various ways.</li>
                    </ul>
                    With these abilities, the magical Box empowers users to explore, analyze, and gain insights from their data, making it a versatile tool for tracking and understanding diverse sets of information.
                </p>
                <img :src="magicalbox" class="compite" alt="">
                <p>The magical Box offers specific capabilities for these parameters:   
                    <ul>
                        <li>Filtering: Users can apply filters to all three types of parameters. </li>
                        <li><b>Attribute:</b>  These describe and categorize data, including "Geographic Location" or "Categorical Data."</li>
                        <li><b> Date and Time:</b>  Users can analyze data over time intervals, such as "Yearly" or "Weekly."</li>
                    </ul>
                </p>
            </div>


            <div class="textnoimage">
                <h4>Assign parameters to charts and tables</h4>
                <p>
                    The critical aspect of this system was to intelligently associate parameters with suitable chart types. this helps us to know what kind of chart should we show to users when they enter measure, attribute or data and time . The prioritization is crucial. To clarify this process, we implemented the following scheme. by this we are able to suggest suitable charts base on parameters the user enters
                </p>
            </div>
            <img :src="meaatt" class="halfcompite" alt="">

            

            <div class="textnoimage">
                <h4>resualt</h4>

            </div>
            <video width="960" height="530" controls class="video">
                <source src="https://dl.dropboxusercontent.com/s/yourfileid/scl/fi/d0xqdeahows40sp2rtld4/siteM.mp4?rlkey=wwbes832lu28n138mi4raa19m&dl=0" type="video/mp4">
                Your browser does not support the video tag.
            </video>


        </div>

        

    </div>
</template>

<script>
import sketch from "../../assets/images/datavisualization/sketch.png"
import insert1 from "../../assets/images/datavisualization/insert1.png"
import insert2 from "../../assets/images/datavisualization/insert2.png"
import insert3 from "../../assets/images/datavisualization/insert3.png"
import magicalbox from "../../assets/images/datavisualization/magicalbox.png"
import meaatt from "../../assets/images/datavisualization/meaatt.png"


    export default {
        data(){
            return{
                sketch,insert1,insert2,insert3,magicalbox,meaatt
            }
        
        
    }
}
</script>

<style scoped>
.container{
    padding-bottom: 60px;
}
.video{

    position: relative;
    transform: translateX(-50%);
    left: 50%;
}
.text{
width: 40%;
}
.img{
    width: 60%;
    padding-inline: 40px;
    box-sizing: border-box;

}

.compite{
    width: 100%;
}
.halfcompite{
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    position: relative;
}
.interviewimg{
    width: 100%;
}

.bullet{
    font-size: 18px;

}
.marg{
    margin-top: 20px;
    margin-bottom: 20px;
}
.nothing{
    margin: 0;
    padding-bottom: 10px;
    padding-inline: 0;
    padding-top: 0;
}
</style>