!<template>
    <div class="container">
        <h2>Initial info</h2>
        <div class="duration newdiv">
            <h3>Duration</h3>
            <div class="row">
                <div class="text ">
                    <p >
                        4 planned 
                                +   
                        2 extended weeks
                    </p>
                    
                </div>
                <div class="img">
                    <img :src="time" class="timeline" alt="">
                </div>
            </div>
        </div>
        <div class="team newdiv">
            <h3>Team</h3>
            <div class="textnoimage">
                <p >
                    I redesigned data visualization software with my lead's guidance and supervision.
                </p>
            </div>
        </div>
        <div class="decisions newdiv">
            <h3>Initial decisions to optimize workflow</h3>
            <div class="textnoimage">
                <p >
                    To boost velocity, we had to: <br/>
                        <span class="bullet">1 - </span>Improve speed by addressing bug fixes by another team, unnecessary meetings, upcoming tasks, and focus solely on this project.<br/>
                        <span class="bullet">2 - </span>Replace status meetings with coordinated communication.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import time from "../../assets/images/datavisualization/timeline.png"
    export default {
        data(){
            return{
                time
            }
        
        
    }
}
</script>

<style scoped>
.container{
    padding-bottom: 60px;
}
.text{
width: 30%;
}
.img{
    width: 70%;
}

.timeline{
    width: 100%;
}
.container{

}
.bullet{
    font-size: 18px;

}
</style>