


<template>
    <div class="case_cont">


        <div class="row scrow">
            <div class="text">
                <div class="center">
                    <router-link :to="project.link" class="project_link project_link_color "> 
                    <p class="title">{{project.title}}</p>
                    <p class="ptext">{{project.caption}}</p>
                    <div class="tag">
                        <p class="smallt"> 2022 - blender - unity - recommender system</p>
                    </div>

                    </router-link>
                    
                </div>
            </div>
            <div class="image">
                 <router-link :to="project.link"> 
                    <div class="image_holder ihSpecial">
                        <img :src="project.image_src" class="env" alt="envirement">
                    </div>
                </router-link>
            </div>
            
            
        </div>
    </div>
</template>

<script>

    export default {
         data: function () {
            return {
            }
        },
        props:{
            project:{
                type:Object
            }
        }
        
    }
</script>

<style scoped>
.case_cont{

    padding-top: 40px;
    padding-bottom: 40px;

}
.image{
    width: 50%;

}
.ihSpecial{
    border-radius: 16px;
    border: 1px solid transparent;
    background: linear-gradient(rgba(0, 0, 0, 0.83), rgb(0, 0, 0)) padding-box,
              linear-gradient(to bottom, rgba(255, 138, 0, 0.49), black) border-box;

    box-shadow: 0px -1px 0px 0px rgba(255, 199, 135, 0.49), 0px 12px 50px -18px rgba(0, 0, 0, 0.2) inset;
    backdrop-filter: blur(10.5px);
}
.smallt{
    font-size: 15px;
    color: rgb(123, 125, 127);
}

.scrow:hover .aside{
    width: 230px;
    transition: .4s;
    position: relative;
    bottom: 10px;
    right:35px;
}
.env{
    height:100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    position: relative;


}
.text{
    width: 50%;
}
.center{
    top: 40%;
    position: relative;
    transform: translateY(-50%);
    margin:5%;
}
.ihSpecial:hover .firstpage{
    scale: 1.1;
}


.ptext{
    margin-top: 10px;
margin-bottom: 10px;
}


@media screen and (max-width: 768px) {
   .box1{
      display: block;
   }

   .text {
      width: 100%;
      order: 2;
   }
    .tag{order:3}
   .image {
      width: 100%;
      order:1;
   }
   .center{
          top: 0%;
        position: relative;
        transform: translateY(0%);
        margin-right:0;
        margin-left:0;
    }
    .env{

    }



}
@media screen and (max-width: 920px) {
    .firstpage{
        width: 45%
    }
    .image_holder{
        min-height: 60vh; 
        margin-inline:0;  
        width: 100%;
    }
    .ptext{

        margin-bottom: 10px;
    }
}
</style>