<template>
    <div>
        <p class="section_title ">Design </p>
        <p class="section_title_2 ">Turning Insights Into Design Ideas </p>

        <p class="section_caption">I decided to divide the application into eight main sections based on a real school and user's needs in the next stage. I mapped problems to these eight sections, and After that, I wrote HMW for each section. The goal was to solve problems that I found in the research stage in a way that met educational standards.  </p>

        <HMWBox/>
        <p class="image_title">some of HMW</p>

        <p class="section_caption">after HMW I needed to spread different parts of the application and design them individually. some parts were the core of the application like online and offline classes but for other parts, I used a diagram like effort/ value and choosed some of the most important parts to design. As the result, these are the main parts/features of the application. </p>
        <ul>
            <li class="section_caption">online class</li>
            <li class="section_caption">offline class</li>
            <li class="section_caption">environment</li>
            <li class="section_caption">notifications</li>
            <li class="section_caption">studying room</li>
            <li class="section_caption">meeting new friend</li>
        </ul>

        <p class="section_title_2 ">ideate and wire frame</p>
        <p class="section_caption">thinking about each part of the application (6 parts) was the core of the design phase but activities like crazy 8 and journey mapping took a part in this phase too. whenever I thought that my design needs to be better I used C8. overall I drow about 80 designs throw C8.</p>
        <img class="last" :src="last" alt="">
    </div>
</template>

<script>
import last from "../../assets/images/last.png"
import HMWBox from "../school/HMW/HMWBox"
import affinity from "../../assets/images/affinity.jpg"
    export default {
        data(){
            return{
                last:last,
                affinity:affinity,
                
            }
        },
        components:{
            HMWBox
        },
        methods:{

        }
    }
</script>

<style >
.section_image1{
    width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
}
.section_image2{
    width: 100% ;
    margin-top: 20px;
    margin-bottom: 20px;
}

.last{
    width: 100%;

    
    left: 0;
}
</style>