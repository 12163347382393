<template>
    <div>
<div class="dialogs">
                <div class="row">
                    <div class="st1">
                        <div class="row">
                            <div class="img">
                                <img :src="st3" alt="" class="st_img">
                            </div>
                            <div class="dialog">
                                <p class="p1">I can't focus on my teacher; I think she speaks slow. I check my Instagram during class.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="st2">
                        <div class="row">
                            <div class="dialog">
                                <p class="p2">I had more than ten friends in my previous school, but I have just two in my new school.
                                </p>
                            </div>
                            <div class="img">
                                 <img :src="st2" alt="" class="st_img">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-12">
                    <div class="st1">
                        <div class="row">
                            <div class="img">
                                <img :src="st1" alt="" class="st_img">
                            </div>
                            <div class="dialog">
                                <p class="p3">When I have a problem with a lesson, there is no one to help me.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="st2">
                        <div class="row">
                            <div class="dialog">
                                <p class="p4">My students don't have enough passion. I should force them to do their homework.
                                </p>
                            </div>
                            <div class="img">
                                 <img :src="th1" alt="" class="st_img">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script>
import st1 from "../../assets/images/avatars/st1.png"
import st2 from "../../assets/images/avatars/st2.png"
import st3 from "../../assets/images/avatars/st3.png"
import th1 from "../../assets/images/avatars/th1.png"
    export default {
        data(){
            return{
                st1,st2,st3,th1,
 
            }
        },
    }
</script>

<style scoped>

</style>