<template>
    <div class="case_cont">
        <div class="row scrow">
            <div class="text">
                <div class="center">
                    <h2 class="title">{{project.title}}</h2>
                    <p class="ptext">{{project.caption}}</p>
                    <router-link :to="project.link" class="project_link project_link_color "> 
                        view
                    </router-link>
                </div>
            </div>
            <div class="image">
                 <router-link :to="project.link"> 
                    <div class="image_holder ihSpecial">
                        <img :src="project.image_src" class="env" alt="envirement">
                         <img :src="firstpage" class="firstpage" alt="envirement">
                    </div>
                </router-link>
            </div>
            
            <div class="tag">
               <p class="smallt"> 2021 - ux - product design</p>
            </div>
        </div>
    </div>
</template>

<script>

import firstpage from "../assets/images/firstpage.png"
    export default {
         data: function () {
            return {
                firstpage:firstpage
            }
        },
        props:{
            project:{
                type:Object
            }
        }
        
    }
</script>

<style scoped>
.case_cont{
    padding-top: 40px;
    padding-bottom: 40px;


}
.image{
    width: 50%;

}
.ihSpecial{
    width: 90% ;
background-color: #a0bcab;
}
.env{
    width:80%;
    position: absolute;
    top: 55%;
    transform: translateY(-50%);

}
.firstpage{
    width: 250px;
    transition: .4s;
    position: absolute;
    bottom: -40px;
    right: -40px;
}

.smallt{
    font-size: 15px;
    color: rgb(123, 125, 127);
}
.text{
    width: 40%;
}
.center{
    top: 40%;
    position: relative;
    transform: translateY(-50%);
    margin:5%;
}
.ihSpecial:hover .firstpage{
    scale: 1.1;
}



.title::after{
    content: "new";
    color: white;
    border-radius: 4rem;
    background-color: red;
    font-size: 13px;
    padding-inline:5px;
    margin-left: 6px;
    bottom: 20px;
    position: relative;
    font-weight: 200;

}
.title{
    line-height: 55px;
}
.ptext{
    line-height: 25px;
}
.project_link_color{

    text-decoration-color: #ced4d9;

}

@media screen and (max-width: 768px) {
   .box1{
      display: block;
   }

   .text {
      width: 100%;
      order: 2;
   }
    .tag{order:3}
   .image {
      width: 100%;
      order:1;
   }
   .center{
          top: 0%;
        position: relative;
        transform: translateY(0%);
        margin-right:0;
        margin-left:0;
    }
    .env{

    }



}
@media screen and (max-width: 920px) {
    .firstpage{
        width: 45%
    }
    .image_holder{
        min-height: 60vh; 
        margin-inline:0;  
        width: 100%;
    }
    .ptext{

        margin-bottom: 10px;
    }
}
</style>