<template>

    <div class="yellow_box mb-12">
            <p class="section_title pl-5">what i learned from the project 💪</p>
        <ul>
            <li class="section_caption">The design does not mean significant changes. Design is a set of minor changes </li>
            <li class="section_caption">Although the user always comes first, sometimes the user does not know what is best for them </li>
            <li class="section_caption">bias is everywhere. a designer shouldn't walk in fog </li>
            <li class="section_caption">remember the goal, not principles </li>
        </ul>
    </div>

</template>

<script>
    export default {
        
    }
</script>

<style scoped>
.yellow_box{
    background-color: #E2E6F7;
    padding-left: 5%;
    padding-right: 2%;
    padding-top: 70px;
    padding-bottom: 70px;
    border-radius: 8px;
}
.section_title{
    margin-top: 0px  !important;
}
</style>