!<template>
    <div class="container">
        <h2>Research</h2>
        
        <div class="team newdiv">
            <h3>competitor analysis</h3>
            <div class="textnoimage">
                <p>
                    I compared software directly and also utilized tools like SimilarWeb for analysis. These were the specific areas we focused on: <br/>
                    <ul>
                        <li>find business gap and potential points<br/></li>
                        <li>Get idea<br/></li>
                        <li>find business trends<br/></li>
                        <li>get information about user needs<br/></li>
                    </ul>
                </p>
  
            </div>
            <img :src="compite" class="compite" alt="">
        </div>
        <div class="duration newdiv">
            <h3>interview</h3>
            <div class="row">
                <div class="text ">
                    <p >
                        During the interviews I conducted, my primary objectives were to:
                        <ul>
                            <li> Gather insights about users' last experiences with data.</li>
                            <li>Listen to their most challenging experiences.</li>
                            <li>Understand their mental model to organizing data, visualizing it, and deriving insights.</li>
                            <li>Identify the difficulties they encountered at each stage of visualization, including inserting data, creating charts, and extracting insights.</li>
                        </ul>
                    </p>
                    
                </div>
                <div class="img">
                    <p class="nothing">sample questions </p>
                    <img :src="interviewquestions" class="interviewimg" alt="">
                </div>
            </div>
        </div>
        <div class="team newdiv">
            <h3>Interview script</h3>
            <div class="textnoimage">
                <p>To avoid transcribing and saving time I use an interview snapshot for each user. 
                </p>
  
            </div>
            <img :src="script" class="compite" alt="">
        </div>
        <div class="team newdiv">
            <h3>Users mental model</h3>
            <div class="textnoimage">
                <p>To create a good flow for the software, it was crucial to comprehend how users mentally organize data and the steps they take to form a mental image of charts. During the interviews, we actively encouraged them to express their thought processes aloud. we explain it when we want to explain storyboard.
                </p>
  
            </div>
        </div>
        <div class="team newdiv">
            <h3>Value proposition canvas</h3>
            <div class="textnoimage">
                <p>With the addition of a new user segment, we've decided to become more familiar with their needs, pain points, and desires, so a value proposition canvas could be helpful.
                </p>
  
            </div>
            <img :src="canvas" class="compite" alt="">
        </div>

    </div>
</template>

<script>
import compite from "../../assets/images/datavisualization/compite.png"
import interviewquestions from "../../assets/images/datavisualization/interviewquestions.png"
import script from "../../assets/images/datavisualization/script.png"
import canvas from "../../assets/images/datavisualization/canvas.png"
    export default {
        data(){
            return{
                compite,interviewquestions,script,canvas
            }
        
        
    }
}
</script>

<style scoped>
.container{
    padding-bottom: 60px;
}
.text{
width: 40%;
}
.img{
    width: 60%;
    padding-inline: 40px;
    box-sizing: border-box;

}

.compite{
    width: 100%;
}
.interviewimg{
    width: 100%;
}

.bullet{
    font-size: 18px;

}
.nothing{
    margin: 0;
    padding-bottom: 10px;
    padding-inline: 0;
    padding-top: 0;
}
</style>