<template>
    <div class="container_all">
        <imageholder/>
            <div class="project_containerw">
            <initialinfo/>
            <problem/>
            <research/>
            <DefineIdeate/>
            <prototype/>
            </div>
    </div>
</template>

<script>

import imageholder from "../../components/Data/header"
import initialinfo from "../../components/Data/initialinfo"
import problem from "../../components/Data/problem"
import research from "../../components/Data/research"
import DefineIdeate from "../../components/Data/Define&Ideate"
import prototype from "../../components/Data/prototype"
//import thirdSection from "../../components/Data/thirdSection"

    export default {

        components:{
            imageholder,initialinfo,problem,research,DefineIdeate,prototype
        }
    }
</script>

<style scope>
.container_all{
    background-color: #00020A;
}
.colorbc{
    background-color: #fff;
    padding-top: 100px!important;
}
p{
    color: #d9dde5 ;
    font-family: 'Poppins', sans-serif !important;

    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
}
h1{
    color: #ffffff;
    font-family: 'DM Sans', sans-serif !important;

}
h2{
    color: #ffffff;
    font-family: 'DM Sans', sans-serif !important;
    font-size: 72px;
    font-weight: 800;
    line-height: 50px;
}
h3{
    color: #ffffff;
    font-family: 'DM Sans', sans-serif !important;
    font-size: 26px;
    font-weight: 500;
    line-height: 0px;
}
h4{
    color: #ffffff;
    font-family: 'DM Sans', sans-serif !important;
    font-size: 20px;
    font-weight: 400;
    line-height: 0px;
    padding-top: 10px;
    padding-left: 10px;
}
.project_containerw {
    margin-inline: 160px;
    position:relative;
    background-color: #00020A;
}
.newdiv{
    margin-top: 80px;
    margin-bottom: 80px;

}


@media screen and (max-width: 520px) {
    .project_container{
        margin-inline: 0%;
    }

}
</style>