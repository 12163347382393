!<template>
    <div class="container">
        <h2>Define & Ideate</h2>
        
        <div class="team newdiv">
            <h3>journey map </h3>
            <div class="textnoimage">
                <p>
                    We decided to develop a user journey map to study our users and enhance the user experience. This map is built upon insights gathered from interviews, with a special focus on our existing users. Our primary goal was to grasp which areas required improvement, so we closely examined user emotions and feedback.<br/>
                    During our analysis, we discovered that there were three aspects that users expressed dissatisfaction with: inserting data, creating charts, and setting parameters.
                </p>
  
            </div>
            <img :src="journy" class="compite" alt="">
            <div class="textnoimage">
                <p>
                    When we identified areas of concern (inputting data, generating a chart, defining parameters and Get insight), we decided to conduct additional interviews and dive deeper into these specific aspects. The outcome provided us with valuable insights and opportunities. 
                </p>
  
            </div>
        </div>

        <div class="team newdiv">
            <h3>Flow of software</h3>
            <div class="textnoimage">
                <p>Our interviews showed that our current flow doesn't match how users naturally think when they work with data. Instead of picking a chart first, most users prefer to begin by considering the specific column they want to measure and then choose a suitable chart. besides, some users like to explore different chart options to find the best one ( 6 out of 10), while others already know which chart to use in advance(around 4 out of 10). This information highlights the importance of adjusting our software's workflow to better fit how users approach data visualization.
                </p>
  
            </div>
            <img :src="flow" class="compite" alt="">
        </div>

        <div class="team newdiv">
            <h3>Opportunity solution tree</h3>
            <div class="textnoimage">
                <h4>opportunities</h4>
                <p>By scripting for users and engaging with stakeholders, we developed an opportunity solution tree. As we clarified in story map, there were four steps that needed refinement (insert data, set parameters, create charts, and get insights). We combined the steps of setting parameters and creating charts, and we added another section suggested by stakeholders called 'conditional formatting.' Then, we incorporated opportunities and combined similar ones.
                </p>
  
            </div>
            <img :src="opp" class="compite" alt="">


            <div class="textnoimage">
                <h4>solutions & Assumptions</h4>
                <p>after prioritizing, we started to work on prioritized each opportunity one by one.💪💪💪
                </p>
  
            </div>
            <img :src="sol" class="compite" alt="">

        </div>

        

    </div>
</template>

<script>
import journy from "../../assets/images/datavisualization/journy.png"
import flow from "../../assets/images/datavisualization/flow3.png"
import opp from "../../assets/images/datavisualization/opp.png"
import sol from "../../assets/images/datavisualization/sol.png"

    export default {
        data(){
            return{
                journy,flow,opp,sol
            }
        
        
    }
}
</script>

<style scoped>
.container{
    padding-bottom: 60px;
}
.text{
width: 40%;
}
.img{
    width: 60%;
    padding-inline: 40px;
    box-sizing: border-box;

}

.compite{
    width: 100%;
}
.interviewimg{
    width: 100%;
}

.bullet{
    font-size: 18px;

}
.nothing{
    margin: 0;
    padding-bottom: 10px;
    padding-inline: 0;
    padding-top: 0;
}
</style>