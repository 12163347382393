<template>
    <div>
        <div class="row ">
            <div class="products_text order_1" >
                <p class="section_title ">final product</p>
                <p class="section_caption">lets review each part of application </p>
                <p class="section_title_2 ma_top">envirement</p>
                <p class="section_caption">I decided to divide the application into eight main sections based on a real school and users needs in the next stage. I mapped problems to these eight sections, and After that, I wrote HMW for each section. The goal was to solve problems that I found in the research stage in a way that met educational standards. </p>
            </div>
            <div class="product_img  order_2">
                <img :src="firstpage" class="section_image1 ">
            </div>
        </div>
        <div class="section">
            <div class="row ">
                <div class="product_img order_2">
                    <img :src="studingroom" class="section_image1 ">
                </div>
                <div class="products_text order_1">
                    <div class="texts_p center">
                        <p class="section_title_2 ma_top">studing room</p>
                        <p class="section_caption">studing room is a part of application that allows students to study together. students can craete focous room or group study.<br>in focous room, students should study for a particular time and then they can have break and talk to others.in group study they can talk with each other and solve their problems in group.</p>
                    </div>
                </div>
            </div>
        </div>


        <div class="row ">
            <div class="products_text order_1">
                <div class="texts_p center">
                    <p class="section_title_2 ma_top">online class</p>
                    <p class="section_caption">There are two modes for classes. Whiteboard and camera mode. I ensured that online classes can make enough interaction and collaboration.</p>
                </div>
            </div>
            <div class="product_img order_2">
                <img :src="whiteboeard" class="section_image1 ">
            </div>
        </div>


            <div class="row ">
                <div class="product_img order_2">
                    <img :src="profile" class="section_image1 ">
                </div>
                <div class="products_text order_1">
                    <div class="texts_p center">
                        <p class="section_title_2 ma_top">studing room</p>
                        <p class="section_caption">profile and medals
                            To encourage students to do side activities and create passion, I decided to make a section in which every student can give medals: such as connection medals, learning medals, research medals, etc.
                            <br/>
                            <strong>challenge</strong>: although a competitive environment can make students work harder, it make learning less valuable for students and they study for prize not for knowledge. So i avoid giving them points for their activities. instead, I used medals that are more general </p>
                    </div>
                </div>
            </div>


    </div>
</template>

<script>
import firstpage from "../../assets/images/firstpage.png"
import studingroom from "../../assets/images/studingroom.png"
import whiteboeard from "../../assets/images/whiteboeard.png"
import profile from "../../assets/images/profile.png"
    export default {
        data(){
            return{
                firstpage:firstpage,
                studingroom:studingroom,
                whiteboeard:whiteboeard,
                profile:profile
            }
        }
    }
</script>

<style >
.products_text{
    width: 58%;
}
.product_img{
    width: 42%;
    padding: 20px;
    box-sizing: border-box;
}
.section_image1{
width: 100%;
}

.center{
    top:36%;
    position: relative;
    transform: translateY(-50%);
}
@media screen and (max-width: 768px) {

.ma_top{
    margin-top: 60px;
}
.product_img{
    width: 100%;
    padding: 0px;
}
.products_text{
    width: 100%;
}
}

</style>