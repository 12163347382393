<template>
    <div>
        <p class="section_title text coloring ">what is it?</p>
        <p class="coloring">this application is a virtual school that contains classes, a studying room, a notification section, a communication center, and some other cool features.</p>
        <p class="section_title nomargintop coloring">the mission</p>
        <div class="row">
            <div class="text_box">
                <p class="coloring">My mission in this project was to find the main problems of students (in the research phase) and create an application based on these problems (design). </p>
            </div>
            <div class="information_box">
                <div class="box_info row">
                    <div class="first">
                        <p class="box_title ">Timeline</p>
                        <p class="box_cap">1 mounth</p>
                    </div>
                    <div class="second">
                        <p class="box_title">team size</p>
                        <p class="box_cap">just me✌️</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style >
.nomargintop{
    margin-top: 40px !important;
}
.information_box{
    width: 20%;
}
.text_box{
    width: 80%;
    padding-right: 10px;
    box-sizing: border-box;
}
.box_info{
    background-color: #E2E6F7;

    padding-inline:20px;
    border-radius: 4px;
}
.box_title{
    font-weight: 400;
}
.coloring{
color: #363636;
}
.box_cap{
    margin-left: 30px;
}

@media screen and (max-width: 920px) {
.first{
    width: 50%;
}
.second{
    width: 50%;
}
    .text_box{
 
        width: 100%;
    }
    .information_box{
          width: 100%;
    }  
    .box_info{
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 auto;
    } 
    
  
}
</style>