<template>
    <div>
        <p class="section_title ">Research </p>
        <p class="section_caption">During the research phase, I conducted five semi-structured interviews online with Persian students and teachers from various elementary schools. The participants were chosen to provide diverse perspectives on the topic. In addition to primary research, I also conducted secondary research to identify the main issues faced by students. For further details on the secondary research, please refer to the linked article. </p>
        <p class="section_title_2">research purpose:</p>
        <ul>
            <li class="section_caption">find online learning challenges</li>
            <li class="section_caption">find different tasks that are done in school</li>
            <li class="section_caption">find the ways that teachers and students use to adopt their self with this situation</li>
        </ul>
             <div class="row  section_caption">
                    <div class="st1">
                        <p class=" just coloring">After conducting each interview, I transcribed and coded the data. Once all five interviews were completed, I analyzed the themes that emerged and organized the results using an affinity diagram. I chose this method because I had a large amount of information to manage, and an affinity diagram provided a clear and effective way to visualize the data.</p>
                    </div>
                    <div class="st2">
                        <div class="interview_card">
                            <p class="section_title_2 interview_sample_titile">sample question:</p>
                            <p class="section_caption interview_sample">
                                <strong>me:</strong> what was the last exam you had?<br>
                                <strong>Zahra:</strong> English exam. I had it last week.<br>
                                <strong>me:</strong> can you remember how did studied for it? can you remember that day?<br>
                            </p>
                        </div>
                    </div>
                </div>
        <p class="section_title_2">main research insights:</p>
            
        <div class="problems row">
            <div class="problem_box "  v-for="project in projects" v-bind:key="project.title" >
                <ProblemBox :pro="project" />
            </div>
        </div>
           <!--<interviewDialog :pro="project" />-->
            
    </div>
</template>

<script>
import ProblemBox from "../school/problembox/ProblemBox"
import interviewDialog from "../school/interviewDialog"
import st1 from "../../assets/images/avatars/st1.png"
import st2 from "../../assets/images/avatars/st2.png"
import st3 from "../../assets/images/avatars/st3.png"
import th1 from "../../assets/images/avatars/th1.png"
    export default {
        data(){
            return{
                st1,st2,st3,th1,
                projects:[
                    {id:0,title:"Learning environment challenges",caption:"students told “we don't have the sense of the school”",num:1}, 
                    {id:1,title:"Learning resource challenges",caption:"there were lack of good resources ",num:2},
                    {id:2,title:"Technological complexity challenges",caption:"some of existing applications had lots of information and it was hard to get right information.",num:3},  
                    {id:3,title:"Student isolation challenges",caption:"during the covid they could not find new friends ",num:4},  
                    {id:4,title:"Self-regulation challenges",caption:"they could not manage thiar time to study. besides some of them had lack of  concentration",num:5},
                    {id:5,title:"Technological sufficiency challenges",caption:"existing appliactions are not enough for all students needs. for example side activites got weeker in online education",num:6},  
                ]
            }
        },
        components:{
            ProblemBox,
            interviewDialog
        }
    }
</script>

<style >
.problem_box{
    width: 33%;
    padding: 10px;
    box-sizing: border-box;
   
}


.dialog p {
  transform: translatey(-3px);

  mix-blend-mode: multiply;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 15px;
  color:black;

  padding: 20px;
  box-sizing: border-box;
  border-radius: 11px;
  position: relative;
}
.p1{
  background-color: #D8BBFB;
}
.p2{
      background-color: #A9E775;
}
.p3{
  background-color: #FFCC65;
}
.p4{
      background-color: #74FFE6;
}
.dialog p:after {
  transform: translatey(-3px);
  content: ".";
  font-weight: bold;
  -webkit-text-fill-color:black;

  font-size: 55px;  
  width: 55px;
  height: 11px;
  line-height: 20px;
  border-radius: 11px;
  position: absolute;
  display: block;
  bottom: -30px;
  
  z-index: -2;
}
.p1:after{
    left: 0;
      text-align: left;
    background-color: #D8BBFB;
}
.p2:after{
    right: 0;
    text-align: right;
       background-color: #A9E775;
}
.p3:after{
    left: 0;
      text-align: left;
    background-color: #FFCC65;
}
.p4:after{
    right: 0;
    text-align: right;
       background-color: #74FFE6;
}
.st1{
    width: 50%;

    box-sizing: border-box;
}
.st2{
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
}
.img{
    width: 27%;

}
.st_img{
    width:100%;
padding: 10%;
    box-sizing: border-box;
}
.dialog{
    width: 73%;
}
.just{
    text-align: justify;
}
.interview_card{
    background-color:#E2E6F7;
    margin: 10px ;
    box-sizing: border-box;
    padding-top:15px ;
    padding-bottom: 20px;
}
.interview_sample{
    margin-right:15px !important;
    margin-left:15px !important;

    margin-bottom: 10px !important;
}
.interview_sample_titile{
    margin-right:15px !important;
    margin-left:15px !important;
    margin-top: 15px !important;

}
@media screen and (max-width: 768px) {

    .problem_box{
        width: 50%;
    }   
    .st1{
        width: 100%;

    }
    .st2{
        width: 100%;

    }
}
@media screen and (max-width: 520px) {

    .problem_box{
        width: 100%;
    }   
}
</style>