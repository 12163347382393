<template>
    <div class="">
        <div class="">
            <p class="section_title ">Test </p>
            <p class="section_caption ">One of the major concerns was that some parts of the application wouldn't discover by students and they wouldn't use them like the finding new friends section. so I conducted A/B test to get sure that users can use all parts and that there wouldn't be any part in the shadow. the tests were :
            </p>

            <p class="section_title_2 ">A/B test</p>
            <p class="section_caption ">I showed two students two mobile pages ( the environment) and asked them to do some tasks and I figured out which one was better. the result demonstrated students could do tasks better in the left design. they found it easier to work with an app like an actual school. unfortunately, I couldn't test other parts with more students.</p>
            <img class="ab" :src="ab" alt="">
        </div>
    </div>
</template>

<script>
import ab from "../../assets/images/ab.png"
    export default {
          data(){
            return{
                ab:ab
                
            }
        },
    }
</script>

<style scoped>
.ab{
    width: 60%;
    left: 0;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    
}

</style>