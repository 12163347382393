<template>
  <div id="app">
    <div class="container">


      <router-view/>

      <div class="footer">
        <div class="containalla">
          <p class="text_center text_title">contact me</p>
          <div class="row">
            
              <div  class="" @click="copyURL(contacts[0].link)">
                <div class="contacts" id="mail">
                  <img :src="contacts[0].img" alt="" class="img_contact">
                </div>
              </div>
              <a :href="contacts[2].link" class="">
                <div class="contacts" id="dribbble">
                  <img :src="contacts[2].img" alt="" class="img_contact">
                </div>
              </a>
              <a :href="contacts[3].link" class="">
                <div class="contacts" id="linkedin">
                  <img :src="contacts[3].img" alt="" class="img_contact">
                </div>
              </a>
            
          </div>
          <p class="text_center created">creted by Alireza</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import image from "./assets/logos/alireza.png"
  import mail from "./assets/icons/mail-fill.svg"
  import dribbble from "./assets/icons/dribbble-line.svg"
  import linkedin from "./assets/icons/linkedin-box-fill.svg"
export default {
  data: function () {
    return {
      image: image,

      mail:mail,
      dribbble:dribbble,
      linkedin:linkedin,
      contacts:[
        {title:'mail',img:mail,link:'alirezafatemi98@gmail.com'}        ,     ,
        {title:'dribbble',img:dribbble,link:'https://dribbble.com/alireza7788'}        ,
        {title:'linkedin',img:linkedin,link:'https://www.linkedin.com/in/alireza-fatemi-5048561a3'}        
      ]
    }
  },
   methods: {
    async copyURL(mytext) {
    try {
      await navigator.clipboard.writeText(mytext);
      alert('mail address Copied');
    } catch($e) {
      alert('Cannot copy',$e);
    }
  }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee&family=Abhaya+Libre&display=swap');

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,300;9..40,400;9..40,600&family=Poppins:wght@300;400;500&display=swap');


@font-face {
    font-family: "Proxima-nova";
    src: local("Proxima-nova"),
     url(./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-1.ttf) ;
}
@font-face {
    font-family: "Proxima-nova-black";
    src: local("Proxima-nova-black"),
     url(./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-Black.ttf) ;
}
@font-face {
    font-family: "Proxima-nova-bold";
    src: local("Proxima-nova-bold"),
     url(./fonts/FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold.ttf) ;
}
body{
  font-family: "Proxima-nova" !important;
  font-size: 11pt ;
  padding: 0 !important;
  margin: 0 !important;

}
p{
    font-family: "Proxima-nova" !important;
    font-size: 11pt ;
    color: #9FA3A8;
    
}
h1{
  font-family: "Proxima-nova-bold" !important;

}

h2{
  font-family: "Proxima-nova-bold" !important;
}
h3{
  font-family: "Proxima-nova-bold" !important;
}
h4{
  font-family: "Proxima-nova-bold" !important;
}

.log{
  font-family:"abeezee";
  font-size: 38pt;
  line-height: 0;
  margin-top: 20px;
}
.log span{
font-family: "Abhaya";
font-weight: 500;
color: #6280ED;
}
#app{


}

.logo_container{
  width: 30%;

}
.header_w{
  margin-inline: 48px;
}
#nav{
  width: 70%;
  padding-top: 10px;
  text-align: right;
  color: white;
}
.footer{
  width: 100%;
  background-color:   #00020D;;
  height: auto;
  padding-bottom: 20px;
}
.containalla{
  left: 50%;
  transform:translateX(-50%) ;
  position: relative;
  width:fit-content;
padding-top: 40px;
  
}


.contacts{
  width: 46px;
  height: 46px;
  border-radius: 8px;
  background-color: #000101;
  margin-inline: 16px;
  transition: .2s;
}
.img_contact{
    left: 50%;
    top: 50%;
  transform:translate(-50%,-50%) ;
  position: relative;
  height: 25px;
  width: 25px;
  color: black;
}
.text_center{
  text-align: center;
}
.text_title{
  font-size: 18pt;
 color: #9FA3A8;
  font-weight: 400;
}
.created{
  font-size: 10pt;
  color: rgb(149, 149, 149);
  font-weight: 400;
  height: auto;
}
#mail:hover{
  background-color: #CA4839;
}
#dribbble:hover{
background-color: #E94C89;
}
#linkedin:hover{
  background-color: #016091;
}

@media only screen and (max-width: 520px) {
.log{
  font-size: 28pt;

  margin-top: 20px;
}

.container{
  margin-inline: 1%;
}
.contacts{

  margin-inline: 5px;

}
}
@media only screen and (max-width: 920px) {


.container{
  margin-inline: 5%;
}
}
</style>
