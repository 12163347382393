<template>
    <div class="linecontainer">
    <div class="pad row">
        <div class="line" id="line1"></div>
        <div class="line" id="line2"></div>
        <div class="line" id="line3"></div>
        <div class="line" id="line4"></div>
        <div class="line" id="line5"></div>
        <div class="line" id="line6"></div>

    </div>
</div>
</template>

<script>
    export default {

    }
</script>

<style scoped>
.linecontainer{
    position: fixed ;   
    top: 0;

    height: 100%;
    width: 100%;
    transform: translateX(-50%);
    left: 50%;

}
.pad{
    position: relative;
    height: 100%;


    z-index: -1;
    margin-inline: 48px;

}
#line1{
    margin-right:9.7%
}
#line2{
    margin-right: 10%;
    margin-left: 10%
}
#line3{
    margin-right: 10%;
    margin-left: 10%
}
#line4{
    margin-right: 10%;
    margin-left: 10%
}
#line5{
    margin-right: 10%;
    margin-left: 10%
}
#line6{
    margin-left:9.7%;

}


.line{

    height: 100%;
   border: .5px solid #f8f6f3;
   box-sizing: border-box;



}
</style>