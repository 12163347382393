import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import about from '../views/About.vue'
import book from '../views/certificatesandbooks.vue'
import virtualschool from'../views/school/index.vue'
import gallery from'../views/gallery/index.vue'
import Datavisualization from'../views/Datavisualization/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: about
  },
  {
    path: '/project/virtualschool',
    name: 'project',
    component: virtualschool
  },
  {
    path: '/project/gallery',
    name: 'gallery',
    component: gallery
  },
  {
    path: '/certificatesandbooks',
    name: 'books',
    component: book
  },
  {
    path: '/project/Datavisualization',
    name: 'Datavisualization',
    component: Datavisualization
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
})

export default router
