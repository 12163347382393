<template>
    <div class="case_cont">


        <div class="row scrow">
          
            <div class="image">
                 <router-link :to="project.link"> 
                    <div class="image_holder ihSpecial">
                        <img :src="project.image_src" class="env" alt="envirement">
                         <img :src="firstpage" class="firstpage" alt="envirement">
                    </div>
                </router-link>
            </div>
            <div class="text">
                <div class="center">
                    <router-link :to="project.link" class="project_link project_link_color "> 
                    <h2 class="title">{{project.title}}</h2>
                    <p class="ptext">{{project.caption}}</p>
                    <div class="tag">
                        <p class="smallt"> 2021 - ux - product design</p>
                    </div>
                    </router-link>
                </div>
            </div>
            
           
        </div>
    </div>
</template>

<script>

import firstpage from "../assets/images/firstpage.png"
    export default {
         data: function () {
            return {
                firstpage:firstpage
            }
        },
        props:{
            project:{
                type:Object
            }
        }
        
    }
</script>

<style scoped>
.case_cont{
    padding-top: 40px;
    padding-bottom: 40px;


}
.smallt{
    font-size: 15px;
    color: rgb(123, 125, 127);
}
.image{
    width: 50%;   
    transition: 1s;

}
.ihSpecial{
    width: 100% ;

    border: 1px solid transparent;
    background: linear-gradient(rgba(0, 0, 0, 0.913), rgb(0, 0, 0)) padding-box,
              linear-gradient(to bottom, #5c64ff98, #5c64ff0b) border-box;
    box-shadow: 0px -1px 0px 0px #5c64ff3a, 0px 12px 50px -18px #5c64ff49 inset;
    backdrop-filter: blur(10.5px);
    border-radius: 20px;
    height: 330px;
    position: relative;
    transition: 1s;
}
.env{
    width:80%;
    top: 55%;
    transform: translateY(-50%);

    position: relative;

}
.firstpage{
    width: 150px;
    transition: .4s;
    position: absolute;
    bottom: -25px;
    right: -25px;
}


.text{
    width: 50%;
}
.center{
    top: 40%;
    position: relative;
    transform: translateY(-50%);
    margin:5%;
}
.scrow:hover .firstpage{
    scale: 1.1;
}

.scrow:hover .ihSpecial{
    background: linear-gradient(rgba(5, 0, 17, 0.975), rgb(0, 0, 0)) padding-box,
              linear-gradient(to bottom, #5c64ff98, #5c64ff0b) border-box;

}




@media screen and (max-width: 768px) {
   .box1{
      display: block;
   }

   .text {
      width: 100%;
      order: 2;
   }
    .tag{order:3}
   .image {
      width: 100%;
      order:1;
   }
   .center{
          top: 0%;
        position: relative;
        transform: translateY(0%);
        margin-right:0;
        margin-left:0;
    }
    .env{

    }



}
@media screen and (max-width: 920px) {
    .firstpage{
        width: 45%
    }
    .image_holder{
        min-height: 60vh; 
        margin-inline:0;  
        width: 100%;
    }
    .ptext{
        margin-bottom: 10px;
    }
}
</style>