<template>
  <div class="contain">
    <div class="introduction">

      <div class="texts">
        <h2 class="p_text">Hi, I'm Alireza<span class="span2">a product<span class="span3"> designer</span></span> <br/> I Design Solutions </h2>

          <div class="links row">
            <div class="putcenter">
              <div class="row">
              <div class="iconscontainer iconconte firstlink">
                <a class="ahref" href="https://www.linkedin.com/in/alireza-fatemi-1377j1999/">
                  <img src="https://upload.wikimedia.org/wikipedia/commons/8/81/LinkedIn_icon.svg" alt="" class="arrow-icon">
                </a>
                
              </div>
              <div class="iconscontainer iconconte secondlink">
                <a  class="ahref" href="https://dribbble.com/alireza7788">
                <img src="../assets/icons/cdnlogo.com_dribbble-icon.svg" alt="" class="arrow-icon">
                </a>  
              </div>

              <div class="iconscontainer iconconte thirdlink">
                <a class="ahref" href="https://t.me/Alirezaf778">
                <img src="../assets/icons/telegram.png" alt="" class="arrow-icon">
                </a>
              </div>

            </div>
          </div>
        </div>
       
      </div>

    </div>
    <Projects/>
  </div>
</template>

<script>
import Projects from "./Projects"

export default {
  name: 'Home',
  components:{
    Projects
  }
}
</script>

<style scoped>
.texts{
  top: 40%;
  position: relative;
  transform: translateY(-50%);
}
.p_text{
    font-size: 70px;
  font-weight:600;
  margin-bottom: 15px;
  line-height: 60px;
  text-align: center;
  color: #ffffff;
}

.contain{
padding-inline: 10%;
padding-top: 100px;
background-color: #00020D;

}
.introduction{
height: 500px;
padding-top: 30px;
}
.span2{
  font-size: 26px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 200;

}.span3 {
background: -webkit-linear-gradient(#5E8BFF, #00F0FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 200;
}
.putcenter{
  left: 50%;
  transform: translateX(-50%);
  position: relative;
}
.arrow-icon{
    position: relative;
    width: 35%;
    height: 35%;
    transform: translate(-50%,-50%);
    left: 50%;
    top: 50%;
}


.links{
  margin-top: 40px;
left: 50%;
position: relative;
transform: translateX(-50%)
}
.linkedin{
  position: relative;

    width: 100%;
    height: 100%;
}
.iconscontainer{
  transition:1s;
  display: flex;
width: 100px;
height: 100px;


justify-content: center;
align-items: center;
flex-shrink: 0;
margin: 20px;


}
.iconconte{
  border-radius: 20px;
  border: 1px solid transparent;
  background: linear-gradient(rgb(0, 0, 0), rgb(0, 0, 0)) padding-box,
                linear-gradient(to bottom, rgb(73, 73, 73), black) border-box;

  box-shadow: 0px -1px 0px 0px rgba(255, 255, 255, 0.74), 0px 12px 41px -13px rgba(9, 9, 9, 0.2) inset;
  backdrop-filter: blur(24.5px);

}
.ahref{
  position: relative;

  width: 100%;
height: 100%;
}
.firstlink{
  transform: rotate(-6deg);
  margin-top:30px;
}
.secondlink{
  transform: rotate(0deg);
 
}
.thirdlink{
  transform: rotate(6deg);
  margin-top:30px;
}
.colorchange{
  fill: black;


}

.alireza{
  font-weight: 600;
  color: #ffffff;
}
.ma-icon{
  margin-inline:5px ;


  border: 1px solid black;
  border-radius: 20px;
}
.iconspack{
  margin-inline-start: 20px;
  position: relative;
  transform: translateY(-25%);
  top : -50%;
  height: fit-content;

}

.iconconte:hover{
  background: linear-gradient(rgb(16, 16, 16), rgb(0, 0, 0)) padding-box,
              linear-gradient(to bottom, rgb(137, 137, 137), black) border-box;
           

}



@media only screen and (max-width: 520px) {
  .line_height{
    line-height: 42px;
  }
  .span{
    display: none;
  }
  .p_text{
    font-size: 32pt;
  }
  .aboutme{
    padding-inline:32px ;
    padding-top:  12px;
    padding-bottom: 12px;
  }
}
</style>
